import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

import Container from '@/components/Container'
import Headings from '@/components/Headings'
import Ph from '@/components/Paragraph'
import LogoLink from '@/components/LogoLink'
import { getActiveLangPath, isDefaultLanguage } from '@/components/Language'

import SocialLinks from '@/components/SocialLinks'
import NavLink from '@/components/NavLink'

import mediaqueries from '@/styles/media'
import Icons from '@/icons'

import AdresIcon from '@/images/agenda.png'
import EmailIcon from '@/images/email.png'
import PhoneIcon from '@/images/phone.png'

import { breakpoints } from '@/utils/styles'

const Footer = (props) => {
  const { navMenuItems, siteGlobal } = props

  return (
    <Section
      css={css`
        background-color: #000000;
      `}
    >
      <Container>
        <Grid>
          <Content>
            <Title>{isDefaultLanguage() ? 'HAKKIMIZDA' : 'OM OSS'}</Title>
            <AboutContent fullWidth>
              <LogoLink
                to={`/`}
                data-a11y="false"
                title="Navigate back to the homepage"
                aria-label="Navigate back to the homepage"
                css={css`
                  margin-bottom: 30px;
                `}
              >
                <Icons.LogoSwedish
                  style={{
                    fontSize: '3em',
                    width: '4em',
                  }}
                />
              </LogoLink>
              {siteGlobal?.generalInformation?.description}
            </AboutContent>
            <SocialLinks links={siteGlobal?.socail} />
          </Content>
          <Content>
            <Title>{isDefaultLanguage() ? 'LİNKLER' : 'LENKER'}</Title>
            <Menu>
              {navMenuItems.map((item) => (
                <FooterLink key={item.title} to={`${getActiveLangPath()}/${item.slug.current}`}>
                  {item.title}
                </FooterLink>
              ))}
            </Menu>
          </Content>
          <Content>
            <Title>{isDefaultLanguage() ? 'İLETİŞİM' : 'KONTAKT'}</Title>
            <AddressInfo>
              <AddressIcon>
                <Icon src={AdresIcon} />
              </AddressIcon>
              <AddressBody>
                <Subtitle>{isDefaultLanguage() ? 'ADRES' : 'ADRESSE'}</Subtitle>
                <ContactParagraph>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    data-a11y="false"
                    aria-label={`Link to Googel Map`}
                    href="https://www.google.com/maps/search/Tvetenveien+152,+0671+Oslo,+Norway/@59.9162125,10.8441821,17z"
                  >
                    {siteGlobal?.generalInformation?.address}
                  </a>
                </ContactParagraph>
              </AddressBody>
            </AddressInfo>
            <AddressInfo>
              <AddressIcon>
                <Icon src={EmailIcon} />
              </AddressIcon>
              <AddressBody>
                <Subtitle>{isDefaultLanguage() ? 'E-POSTA' : 'E-POST'}</Subtitle>
                <ContactParagraph>
                  <a
                    rel="noopener noreferrer"
                    data-a11y="false"
                    aria-label={`Link to Email Address`}
                    href={`mailto:test@gmail.com`}
                  >
                    {siteGlobal?.generalInformation?.email}
                  </a>
                </ContactParagraph>
              </AddressBody>
            </AddressInfo>
            <AddressInfo>
              <AddressIcon>
                <Icon src={PhoneIcon} />
              </AddressIcon>
              <AddressBody>
                <Subtitle>{isDefaultLanguage() ? 'TELEFON' : 'TELFON'}</Subtitle>
                <ContactParagraph>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    data-a11y="false"
                    aria-label={`Link to Phone Number`}
                    href={`tel:23123154`}
                  >
                    {siteGlobal?.generalInformation?.phone}
                  </a>
                </ContactParagraph>
              </AddressBody>
            </AddressInfo>
          </Content>
        </Grid>
      </Container>
      <Copyright>
        <Container>
          <Paragraph fullWidth>
            © {`${new Date().getFullYear()}`}{' '}
            {isDefaultLanguage()
              ? 'Hasene Norge. Tüm hakları saklıdır'
              : 'Hasene Norge. Alle rettigheter forbehold.'}
          </Paragraph>
        </Container>
      </Copyright>
    </Section>
  )
}

export default Footer

const Section = styled.footer`
  position: relative;
  padding: 84px 0 40px;
  color: #fff;
  font-size: 14px;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 150px 1fr;
  gap: 3.5rem;
  padding: 0 10px 50px;

  ${mediaqueries.desktop`
    grid-template-columns: 2fr 150px 1fr;
  `};

  ${mediaqueries.tablet`
    grid-template-columns: 1fr;
  `};

  ${mediaqueries.phablet`
    grid-template-columns: 1fr;
  `};
`

const Content = styled.div`
  margin-left: -2px;
`
const Menu = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const FooterLink = styled(NavLink)`
  font-weight: 400;
  font-size: 1.5rem;
  margin-bottom: 3rem;
  padding: 0;

  @media (max-width: ${breakpoints.s}px) {
    margin-left: 0;
  }
`

const AddressInfo = styled.div`
  display: flex;
  margin-bottom: 30px;
`

const AddressIcon = styled.div`
  padding-right: 15px;
`

const AddressBody = styled.div`
  display: flex;
  flex-direction: column;
`

const Icon = styled.img`
  width: 20px;
`

const Title = styled(Headings.h4)`
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 30px;
`
const Subtitle = styled(Title)`
  margin: 0;
  font-size: 1.4rem;

  @media (max-width: ${breakpoints.s}px) {
    font-size: 1.3rem;
  }
`
const Copyright = styled.div`
  padding-top: 37px;
  text-align: center;
  border-top: 1px solid #454545;

  p {
    font-size: 14px;
    margin: 0;
    color: ${(p) => p.theme.colors.white};
  }
`

const Paragraph = styled(Ph)`
  padding: 0 20px;
`
const AboutContent = styled(Ph)`
  color: #fff;
  font-size: 14px;
  max-width: 500px;
  margin-bottom: 35px !important;
  padding: 0 !important;
`

const ContactParagraph = styled(Paragraph)`
  padding: 0;
  margin: 0;
  font-size: 1.4rem;

  @media (max-width: ${breakpoints.s}px) {
    font-size: 1.3rem;
  }

  a {
    color: ${(p) => p.theme.colors.articleText};
  }
`
