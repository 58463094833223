import React from 'react'
import { isDefaultLanguage } from '@/components/Language'

import Icons from '@/icons'

import { EmptyCartRoot, Title } from './styles'

const EmptyCart = ({ isEmptyCart }) => {
  return (
    <EmptyCartRoot isEmptyCart={isEmptyCart}>
      <Icons.Sad />
      <Title>{isDefaultLanguage() ? `Bağış sepetiniz boş!` : `Ingen valgte donasjoner!`}</Title>
    </EmptyCartRoot>
  )
}
export default EmptyCart
