import styled from '@emotion/styled'
import { css } from '@emotion/core'
import NavLink from '@/components/NavLink'
import { breakpoints } from '@/utils/styles'

const dropdownItemActive = props =>
  props.active &&
  css`
    text-decoration: none;
    color: #212529;
    background-color: #cce5ff;
  `

const dropdownItemDisabled = props =>
  props.disabled &&
  css`
    color: #e2e6ea;
    background-color: transparent;
  `

const DropdownItem = styled(NavLink)`
  display: block;
  color: rgb(45, 47, 49);
  padding: 0.8rem 2rem;
  margin: 0;
  font-weight: 400;
  font-size: 1.4rem;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  text-shadow: none;
  border: 0;

  @media (min-width: ${breakpoints.m}px) {
    &:hover,
    &:focus {
      color: #212529;
      text-decoration: none;
      background-color: #f0f0f0;
    }
  }

  @media (max-width: ${breakpoints.s}px) {
    margin: 0;
    color: #f0f0f0;
    font-size: 1.6rem;
  }

  ${props => dropdownItemActive(props)};
  ${props => dropdownItemDisabled(props)};
`

export { DropdownItem }
