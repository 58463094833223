import React from 'react'
import styled from '@emotion/styled'

import { DropdownItem } from './DropdownItem'
import { DropdownMenu } from './DropdownMenu'
import NavLink from '@/components/NavLink'
import Button from '@/components/Button'
import { getActiveLangPath } from '@/components/Language'

import { useResizer } from '@/hooks'
import { breakpoints } from '@/utils/styles'

const StyledDropdown = styled.div`
  position: relative;
  display: inline-block;

  & + div {
    margin-left: 3rem;
  }

  @media (max-width: ${breakpoints.s}px) {
    width: 60%;
    text-align: center;
  }
`

const NavButton = styled(Button)`
  font-weight: 400;
  // padding: 1rem 2rem;
`

const Link = styled(NavLink)`
  font-weight: 400;
`

const Dropdown = ({ items = [], title, to, onClick }) => {
  const [hidden, setHidden] = React.useState(true)
  const isMobile = useResizer()

  return (
    <StyledDropdown onMouseLeave={() => !isMobile && setHidden(true)}>
      {items.length > 0 ? (
        <NavButton
          dropdownToggle
          onClick={() => isMobile && setHidden(!hidden)}
          onMouseEnter={() => !isMobile && setHidden(false)}
        >
          {title}
        </NavButton>
      ) : (
        <Link
          title={title}
          to={`${getActiveLangPath()}/${to}`}
          activeClassName="active"
          onClick={() => {
            onClick()
            !isMobile && setHidden(true)
          }}
        >
          {title}
        </Link>
      )}
      {items.length > 0 && (
        <DropdownMenu
          hidden={hidden}
          isMobile={isMobile}
          toggle={() => setHidden((prevHidden) => !prevHidden)}
          onClick={() => {
            onClick()
            !isMobile && setHidden(true)
          }}
        >
          {items.map((item, idx) => (
            <DropdownItem key={`${item.title}-${idx}`} to={`${getActiveLangPath()}/${item.link}`}>
              {item.title}
            </DropdownItem>
          ))}
        </DropdownMenu>
      )}
    </StyledDropdown>
  )
}

export default Dropdown
