import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { breakpoints } from '@/utils/styles'

const dropdownMenuHidden = props =>
  props.hidden
    ? css`
        display: none;
      `
    : css`
        display: block;
      `

const isFullWidth = props =>
  props.fullWidth
    ? css`
        min-width: 100%;
      `
    : null

const borderRadius = props => {
  if (props.noRadius) {
    return css`
      border-radius: 0;
    `
  }

  return css`
    border-radius: 0.25rem;
  `
}

const StyledDiv = styled.div`
  position: absolute;
  top: ${({ isMobile }) => (isMobile ? '30px' : '20px')};
  z-index: 1000;
  min-width: 10rem;
  font-size: 1rem;
  color: rgb(45, 47, 49);
  text-align: left;
  background-color: rgb(255, 255, 255);
  background-clip: padding-box;
  padding: 0.5rem 0px;
  margin: 0.125rem 0px 0px;
  list-style: none;
  text-align: center;

  @media (max-width: ${breakpoints.s}px) {
    background-color: rgb(0, 0, 0);
    left: 0;
    right: 0;
    padding: 2em 0;
  }

  @media (min-width: ${breakpoints.m}px) {
    left: -10%;
    margin: 0 auto;
    text-align: left;
    width: auto;
    white-space: nowrap;
  }

  ${props => borderRadius(props)};
  ${props => dropdownMenuHidden(props)};
  ${props => isFullWidth(props)};
`

class DropdownMenu extends React.Component {
  constructor(props) {
    super(props)
    this.container = React.createRef()
  }

  componentDidMount() {
    this.addEvents()
  }

  componentWillUnmount() {
    this.removeEvents()
  }

  addEvents() {
    document.addEventListener('click', this.handleDocumentClick, true)
  }

  removeEvents() {
    document.removeEventListener('click', this.handleDocumentClick, true)
  }

  handleDocumentClick = e => {
    const container = this.container.current

    if (this.props.hidden || container === null) {
      return
    }

    const containerParent = container.parentNode

    if (
      (containerParent === e.target || !containerParent.contains(e.target)) &&
      container !== e.target &&
      !container.contains(e.target)
    ) {
      this.props.toggle()
    }
  }

  render() {
    const { children, alwaysVisible, hidden, isMobile, ...rest } = this.props

    return (
      <StyledDiv
        ref={this.container}
        hidden={!alwaysVisible && hidden}
        isMobile={isMobile}
        {...rest}
      >
        {children}
      </StyledDiv>
    )
  }
}

DropdownMenu.defaultProps = {
  hidden: false,
  toggle: () => {},
}

export { DropdownMenu }
