import React from 'react'
import SVG from '@/components/SvgIcon'

const Burger = props => (
  <SVG {...props}>
    <path d="M21,18.375H3v-.75H21Zm0-6H3v-.75H21Zm0-6H3v-.75H21Z" />
  </SVG>
)

export default Burger
