import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import Container from '@/components/Container'
import SocialLinks from '@/components/SocialLinks'
import Language from '@/components/Language'

import mediaqueries from '@/styles/media'
import { breakpoints } from '@/utils/styles'

const TopHeader = ({ transparent, siteGlobal }) => {
  return (
    <Header transparent={transparent}>
      <TopHeaderContainer>
        <Contact>
          <a href={`tel:${siteGlobal?.generalInformation?.phone}`} rel="nofollow">
            {siteGlobal?.generalInformation?.phone}
          </a>
          <a href={`mailto:${siteGlobal?.generalInformation?.email}`} rel="nofollow">
            {siteGlobal?.generalInformation?.email}
          </a>
        </Contact>

        <SocialIcons>
          <div>
            <SocialLinks links={siteGlobal?.socail} fill={`#adefee`} />
          </div>
          <Language />
        </SocialIcons>
      </TopHeaderContainer>
    </Header>
  )
}

TopHeader.propTypes = {
  siteTitle: PropTypes.string,
}

TopHeader.defaultProps = {
  siteTitle: ``,
}
export default React.memo(TopHeader)

const Header = styled.section`
  padding: 12px 0;
  min-height: 34px;
  background-color: ${(p) => (p.transparent ? p.theme.colors.primary : p.theme.colors.black)};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
`

const TopHeaderContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${mediaqueries.tablet`
    flex-direction: column;
    justify-content: center;
  `}
`

const Contact = styled.ul`
  display: flex;
  align-items: center;

  a {
    color: #adefee;

    &:hover {
      color: ${(p) => p.theme.colors.white};
    }

    & + a {
      border-left: 1px solid #adefee;
      margin-left: 15px;
      padding-left: 15px;
    }
  }

  @media (max-width: ${breakpoints.m}px) {
    justify-content: center;
    align-items: center;
  }

  @media (max-width: ${breakpoints.s}px) {
    display: none;
  }
`

const SocialIcons = styled.div`
  display: flex;

  a {
    color: #adefee;
    margin-left: 15px !important;

    /* &:hover {
      svg {
        &:hover * {
          fill: ${(p) => p.theme.colors.white};
        }
        * {
          transition: fill 0.25s var(--ease-in-out-quad);
        }
      }
    } */
  }

  @media (max-width: ${breakpoints.s}px) {
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`

// const TopLink = styled(Link)`
//   border-left: 1px solid #69cbc9;
//   padding-left: 15px;

//   &:hover {
//     color: ${p => p.theme.colors.white};
//   }
// `
