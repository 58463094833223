import * as React from 'react'
import styled from '@emotion/styled'
import { MdClose } from 'react-icons/md'

import { formatAmountForDisplay } from '@/utils/helpers'
import { useCartContext } from 'context/CartContext'

import {
  CartListItemRoot,
  Thumbnail,
  Info,
  Name,
  Meta,
  Remove,
  Quantity,
  QtyFieldset,
  AddButton,
} from './styles'

const validate = (count) => {
  return parseFloat(count) | count
}

export default ({ product }) => {
  const { cartDispatch } = useCartContext()
  const [price, setPrice] = React.useState(product.price)

  React.useEffect(() => {
    if (product.price) {
      setPrice(product.price)
    } else {
      setPrice(0)
    }
  }, [product])

  const handleUpdatePrice = ({ id, price }) => (e) => {
    const newPrice = validate(e.currentTarget.value)

    let nextPrice

    if (newPrice > 0) {
      nextPrice = newPrice

      cartDispatch({
        type: 'INCREMENT_PRICE',
        id,
        price: nextPrice,
      })
    } else {
      cartDispatch({
        type: 'REMOVE_FROM_CART',
        id,
      })
    }

    setPrice(nextPrice)
  }

  const handleIncreasePrice = (product) => {
    const nextPrice = !product.fixedPrice ? price + 1 : price + product.defaultPrice

    cartDispatch({
      type: 'INCREMENT_PRICE',
      id: product.id,
      price: nextPrice,
    })

    setPrice(nextPrice)
  }

  const handleDecreasePrice = (product) => {
    let nextPrice = !product.fixedPrice ? price - 1 : price - product.defaultPrice

    if (nextPrice > 0) {
      cartDispatch({
        type: 'DECREMENT_PRICE',
        id: product.id,
        price: nextPrice,
      })
    } else {
      nextPrice = 0
      cartDispatch({
        type: 'REMOVE_FROM_CART',
        id: product.id,
      })
    }
    setPrice(nextPrice)
  }

  const handleDeleteProduct = (id) => () => {
    cartDispatch({
      type: 'REMOVE_FROM_CART',
      id,
    })
  }

  return (
    <CartListItemRoot>
      <Thumbnail image={product.productImage} alt={product.title} />
      <Info>
        <Name>{product.title}</Name>
        <Meta fullWidth>{`${!product.fixedPrice ? 'min. ' : ''}${formatAmountForDisplay(
          product.price
        )}`}</Meta>
      </Info>
      <Container>
        <AddButton
          small
          disabled={price === 0}
          // disabled={price === 0 || price === product.defaultPrice}
          onClick={() => handleDecreasePrice(product)}
        >
          —
        </AddButton>
        <QtyFieldset isFixedPrice>
          <Quantity
            id={product.id}
            name={product.title}
            type="text"
            value={validate(price)}
            onChange={(e) => {
              setPrice(e.target.value)
              handleUpdatePrice(product)
            }}
            onBlur={handleUpdatePrice(product)}
            readonly={product.fixedPrice}
            disabled={product.fixedPrice}
          />
        </QtyFieldset>
        <AddButton small onClick={() => handleIncreasePrice(product)}>
          +
        </AddButton>
      </Container>
      <Remove onClick={handleDeleteProduct(product.id)}>
        <MdClose />
      </Remove>
    </CartListItemRoot>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
`
