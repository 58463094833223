import styled from '@emotion/styled'

import Button from '@/components/Button'
import Paragraph from '@/components/Paragraph'
import Headings from '@/components/Headings'
import Image from '@/components/Image'
import { Fieldset, Input, Label } from '@/components/shared/FormElements'
import CartThumbnail from './CartThumbnail'

import { breakpoints } from '@/utils/styles'

export const CartRoot = styled.div`
  background: rgb(245 243 247);
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(110%);
  transition: transform 0.35s;
  width: 400px;
  box-shadow: -10px 0px 10px rgba(0, 0, 0, 0.15);
  will-change: transform;
  z-index: 1000;
  overflow-y: scroll;

  ${({ isEmptyCart }) =>
    isEmptyCart
      ? `
    top: 160px;
    bottom: auto;
  `
      : `
    height: 100%;
  `}

  ${({ isOpenCart }) =>
    isOpenCart
      ? `
      transform: translateX(0%);
      `
      : `
      transform: translateX(110%);
  `}


  ::after {
    background-color: ${(p) => p.theme.colors.card};
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 250ms;
  }
  &.loading {
    ::after {
      opacity: 0.9;
      pointer-events: all;
    }
  }

  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
  }
`

export const Heading = styled.header`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  ${({ isEmptyCart }) =>
    isEmptyCart &&
    `
  flex-direction: row;
  justify-content: space-between;
  `}
`

export const Title = styled(Headings.h2)`
  font-size: 1.8rem;
  margin: 0;
  position: relative;
`

export const Content = styled.div`
  bottom: 0;
  overflow-y: auto;
  padding: 15px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const ItemsNumber = styled.span`
  align-items: center;
  background: ${(p) => p.theme.colors.secondary};
  border-radius: 50%;
  color: ${(p) => p.theme.colors.white};
  display: flex;
  font-size: 1.3rem;
  font-weight: bold;
  height: 36px;
  justify-content: center;
  width: 36px;
`

export const ItemsInCart = styled.div`
  align-items: center;
  display: flex;
  line-height: 1.2;
  text-align: right;
  color: ${(p) => p.theme.colors.articleText};
  font-size: 1.4rem;
  text-transform: uppercase;

  ${ItemsNumber} {
    margin-right: 8px;
    margin-left: 8px;
  }
`

export const Costs = styled.div`
  display: flex;
  flex-direction: column;
  background: rgb(1 168 166 / 21%);
  padding: 20px;
  margin: 50px 0;
  border: 2px dashed #01a8a6;
`

const Cost = styled(`div`)`
  display: flex;
  padding: 0 8px 4px;
  :last-child {
    padding-bottom: 0;
  }
  span {
    flex-basis: 60%;
    font-size: 0.9rem;
    /* text-align: right; */
  }
  strong {
    flex-basis: 40%;
    text-align: right;
  }
`

export const Total = styled(Cost)`
  /* border-top: 1px solid ${(p) => p.theme.colors.border}; */
  color: ${(p) => p.theme.colors.articleText};
  /* margin-top: 8px;
  padding-top: 12px; */
  align-items: center;
  font-size: 1.4rem;

  span {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.3rem;
  }

  strong {
    color: ${(p) => p.theme.colors.black};
    text-transform: uppercase;
  }
`

export const CheckoutButton = styled(Button)`
  font-size: 1.25rem;
  margin: 24px 0 16px;
  width: 100%;
  background-color: ${(p) => p.theme.colors.primary};

  ${({ disabled }) =>
    disabled &&
    `
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.6;
  `}
`

export const Viewport = styled.div`
  width: 100%;
`

export const CheckoutWrapper = styled(Viewport)``

export const CartTitleWrapper = styled(Viewport)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
`

export const CartIndicatorRoot = styled.div`
  background: #ffdf37;
  border-radius: 2px;
  color: #663399;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  justify-content: center;
  left: 0;
  padding: 8px 12px;
  position: absolute;
  top: calc(50vh + 16px);
  transform: translateX(calc((100% + 40px) * -1));
`

export const CartListRoot = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

export const Headers = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.colors.border};
  display: flex;
  justify-content: space-between;

  span {
    flex-basis: 60px;
    flex-grow: 0;
    font-size: 1.4rem;
    padding-bottom: 12px;
    text-align: center;
    text-transform: uppercase;
    color: ${(p) => p.theme.colors.articleText};

    &:first-of-type {
      flex-grow: 1;
      text-align: left;
    }

    &:nth-of-type(2) {
      margin: 0 8px;
    }
  }
`

export const CartListItemRoot = styled('li')`
  align-items: center;
  border-bottom: 1px solid #f5f3f7;
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
`

export const CartThumbnailRoot = styled(Image)`
  border: 1px solid #f5f3f7;
  border-radius: 2px;
  height: 50px;
  width: 50px;
`

export const Thumbnail = styled(CartThumbnail)`
  flex-grow: 0;
  margin-left: 4px;
  margin-right: 12px;
`

export const Info = styled.div`
  flex-grow: 1;
`

export const Name = styled.h3`
  font-size: 16px;
  line-height: 1.45;
  font-weight: 600;
  font-family: ${(p) => p.theme.fonts.title};
  color: inherit;
  margin-bottom: 5px;
`

export const Meta = styled(Paragraph)`
  display: block;
  font-family: ${(p) => p.theme.fonts.title};
  font-size: 1.25rem;
  font-style: normal;
`

export const Quantity = styled(Input)`
  flex-grow: 0;
  /* height: 44px; */
  padding: 0 8px 0;
  text-align: center;
  width: 50px;

  @media (max-width: ${breakpoints.m}px) {
    width: 50px !important;
    height: 43px;
  }

  @media (min-width: ${breakpoints.l}px) {
    width: 65px !important;
  }

  @media (max-width: ${breakpoints.s}px) {
    height: auto;
  }
`

export const Remove = styled(Button)`
  border: 1px dotted ${(p) => p.theme.colors.white};
  display: flex;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  padding: 0;
  margin: 0;
  font-size: 14px;
  svg {
    height: 20px;
    margin: 0;
    width: 20px;
  }
`

export const EmptyCartRoot = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 350px;
  justify-content: center;

  ${({ isEmptyCart }) =>
    isEmptyCart &&
    `
  height: auto;
  `}
`

export const SadCartCopy = styled.div`
  color: #8c65b3;
  margin-top: 24px;
  max-width: 200px;
  text-align: center;
  p {
    margin: 0;
  }
`

export const QtyFieldset = styled(Fieldset)`
  border: none;
  display: flex;
  margin: 0;
  padding: 0;
  flex-grow: 0;
  margin-right: 16px;

  ${({ isFixedPrice }) =>
    isFixedPrice &&
    `
    margin: 0 8px;

    ${Input} {
      @media (min-width: ${breakpoints.l}px) {
        width: 70px !important;
        height: 40px;
      }
    }
  `}

  ${Label} {
    text-align: center;
  }
  ${Input} {
    padding: 8px 8px;
    text-align: center;

    @media (max-width: ${breakpoints.m}px) {
      width: 50px;
      height: 43px;
    }

    @media (max-width: ${breakpoints.s}px) {
      width: 66px;
      height: 42px;
    }

    @media (min-width: ${breakpoints.l}px) {
      width: 100px;
      height: 43px;
    }
  }
`

export const AddButton = styled(Button)`
  font-size: 14px;
  align-self: flex-end;
  font-family: ${(p) => p.theme.fonts.title};
  padding: 8px 8px;
  margin: 0;
  height: 43px;
  width: 40px;
  background: transparent;
  color: ${(p) => p.theme.colors.primary};
  border: 1px solid ${(p) => p.theme.colors.primary};
  transition: border 0.33s var(--ease-out-quart), color 0.33s var(--ease-out-quart);

  &:hover {
    background: transparent;
    color: ${(p) => p.theme.colors.secondary};
    border: 1px solid ${(p) => p.theme.colors.secondary};
  }

  ${({ small }) =>
    small &&
    `
    height: 25px !important;
    width: 25px !important;
    padding: 0 !important;
  `}

  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
    cursor:not-allowed !important;
    opacity: 0.4;
  `}

  @media (max-width: ${breakpoints.m}px) {
    font-size: 14px;
    padding: 10px 10px;
  }

  @media (max-width: ${breakpoints.s}px) {
    font-size: 13px;
  }
`
