import styled from '@emotion/styled'
import IconButton from '@/components/IconButton'

export const BackToTop = styled(IconButton)`
  z-index: 2;
  background-color: ${p => p.theme.colors.secondary};
  color: ${p => p.theme.colors.white};
  border-radius: 50%;
  transition: 0.3s;
  position: fixed;
  right: 20px;
  bottom: 2vh;
  opacity: ${p => (p.show ? 1 : 0)};
  visibility: ${p => (p.show ? 'visible' : 'hidden')};
  padding: 12px;
  &:hover {
    transform: scale(1.15);
  }
`
