import { format, parseISO } from 'date-fns'

const locales = ['no-NO']
const currency = 'nok'

export function formatAmountForDisplay(amount) {
  let numberFormat = new Intl.NumberFormat(locales, {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
  })
  return numberFormat.format(amount)
}

export function formatSessionAmount(amount) {
  let numberFormat = new Intl.NumberFormat(locales, {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
  })
  return numberFormat.format(parseFloat(amount) / 100)
}

export function formatAmountForStripe(amount) {
  let numberFormat = new Intl.NumberFormat(locales, {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
  })
  const parts = numberFormat.formatToParts(amount)
  let zeroDecimalCurrency = true
  for (let part of parts) {
    if (part.type === 'decimal') {
      zeroDecimalCurrency = false
    }
  }
  return zeroDecimalCurrency ? amount : Math.round(amount * 100)
}

export const substrDescription = (description = '', length = false) => {
  if (length && description.length > length) {
    //trim the string to the maximum length
    let trimmedString = description.substr(0, length)
    //re-trim if we are in the middle of a word

    if (trimmedString.lastIndexOf('.') > 0) {
      trimmedString = trimmedString.substr(0, trimmedString.lastIndexOf('.') + 1)
    } else {
      trimmedString =
        trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))) +
        '...'
    }
    return trimmedString
  } else {
    return description
  }
}

export const normalizeProductNodes = (nodes, striplist) => {
  return nodes.map((node) => {
    if (!node.metafields) return node

    const fields = node.metafields.reduce((prev, curr) => {
      prev[curr.key] = curr.value
      return prev
    }, {})

    const merged = { ...node, ...fields }
    const keys = Object.keys(merged).filter((key) => !striplist.includes(key))

    return keys.reduce((curr, key) => {
      curr[key] = merged[key]
      return curr
    }, {})
  })
}

export const setLocalStorage = (key, value, days) => {
  if (!value) {
    value = 1 // default set to 1
  }

  if (!days) {
    days = 1
  }

  const now = new Date().getTime()
  const timestamp = now + days * 24 * 60 * 60 * 1000
  const isKeySet = loadLocalStorage(key)

  if (isKeySet) {
    const record = { value, timestamp }
    window.localStorage.setItem(key, JSON.stringify(record))
  }
}

export const loadLocalStorage = (key) => {
  const now = new Date().getTime()
  const record = JSON.parse(window.localStorage.getItem(key))

  if (record === null) {
    return true
  }
  if (record && now > record.timestamp) {
    window.localStorage.removeItem(key)
    return false
  }
  return false
}

export function buildImageObj(source) {
  const imageObj = {
    asset: { _ref: source?.asset?._ref || source?.asset?._id },
  }

  if (source.crop) imageObj.crop = source.crop
  if (source.hotspot) imageObj.hotspot = source.hotspot

  return imageObj
}

const defaults = { nonTextBehavior: 'remove' }

export function toPlainText(blocks, opts = {}) {
  const options = Object.assign({}, defaults, opts)
  return blocks
    .map((block) => {
      if (block._type !== 'block' || !block.children) {
        return options.nonTextBehavior === 'remove' ? '' : `[${block._type} block]`
      }

      return block.children.map((child) => child.text).join('')
    })
    .join('\n\n')
}

export function getBlogUrl(publishedAt, slug) {
  if (!slug) {
    return ''
  }
  if (!publishedAt) {
    return `/blog/${slug.current || slug}`
  }
  return `/blog/${format(parseISO(publishedAt), 'yyyy/MM')}/${slug.current || slug}/`
}
