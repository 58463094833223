import * as React from 'react'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import { Img } from '@/utils/styles'
import { buildImageObj } from '@/utils/helpers'
import imageUrlFor from '@/utils/image-url'

import clientConfig from '../../../client-config'

const Image = ({ component: Component = Img, imageId, width, height, alt, src, ...other }) => {
  const [loaded, setLoaded] = React.useState(false)

  let fluidProps

  if (imageId && !/gif/.test(imageId)) {
    fluidProps = getFluidGatsbyImage(imageId, { maxWidth: width || 2400 }, clientConfig.sanity)
  }

  return fluidProps ? (
    <Component fluid={fluidProps} alt={alt} defaultFadeIn={700} {...other} />
  ) : (
    <img
      alt={alt}
      src={src ? imageUrlFor(buildImageObj(src)).width(width).height(height).url() : undefined}
      className={loaded ? 'is-loaded' : undefined}
      onLoad={() => {
        setLoaded(true)
      }}
    />
  )
}

export default Image
