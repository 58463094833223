import React from 'react'
import { Global, css } from '@emotion/core'
import styled from '@emotion/styled'
import { toast } from 'react-toastify'

import { useCartContext } from 'context/CartContext'
import NavigationHeader from '@/components/Navigation/Navigation.Header'
import NavigationFooter from '@/components/Navigation/Navigation.Footer'
import Cart from '@/components/Cart'
import TopHeader from '@/components/TopHeader'
import ScrollTop from '@/components/ScrollTop'

import { WithIsScrolled } from '@/hooks'
import { globalStyles } from '@/styles'

import 'react-toastify/dist/ReactToastify.css'

toast.configure()

const Layout = (props) => {
  const { children, navMenuItems, siteGlobal } = props

  const { isOpenCart } = useCartContext()

  const mainMenuItems = navMenuItems.filter((item) => item?.menuLocation?.mainMenu)
  const footerMenuItems = navMenuItems.filter((item) => item?.menuLocation?.footerMenu)

  return (
    <>
      <Global styles={globalStyles} />
      {isOpenCart ? <Overlay isOpenCart={isOpenCart} /> : null}
      <Cart />
      <WithIsScrolled>
        {({ isScrolled }) => (
          <>
            <TopHeader transparent={!isScrolled} scrolled={!isScrolled} siteGlobal={siteGlobal} />
            <NavigationHeader
              navMenuItems={mainMenuItems}
              scrolled={!isScrolled}
              transparent={!isScrolled}
            />
          </>
        )}
      </WithIsScrolled>
      {children}
      <NavigationFooter navMenuItems={footerMenuItems} siteGlobal={siteGlobal} />
      <ScrollTop
        showBelow={500}
        css={css`
          position: fixed;
          right: 1.5em;
          bottom: 1em;
        `}
      />
    </>
  )
}

// const Container = styled.div`
//   position: relative;
//   background: ${(p) => p.theme.colors.background};
//   transition: ${(p) => p.theme.colorModeTransition};
//   min-height: 100vh;
// `

const Overlay = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: -1;
  position: fixed;
  align-items: center;
  justify-content: center;
  transition: backdrop-filter 0.3s;
`

// Removed due to changing behavoir of Donation Button
// ${({ isOpenCart }) =>
//   isOpenCart &&
// z-index: 100;
// background: rgba(0, 0, 0, 0.05);
// backdrop-filter: blur(2px);
// }

export default React.memo(Layout)
