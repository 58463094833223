import React from 'react'
import { css } from '@emotion/core'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { MdArrowForward, MdErrorOutline } from 'react-icons/md'

import { isDefaultLanguage, getActiveLang } from '@/components/Language'
import Spinner from '@/components/Spinner'
import getStripe from '@/utils/getStripe'
import {
  Form,
  CheckoutInput,
  Select,
  Errors,
  ErrorSign,
  ErrorMsg,
} from '@/components/shared/FormElements'
import { useCartContext } from 'context/CartContext'
import { formatAmountForStripe } from '@/utils/helpers'

import { CheckoutButton } from './styles'

const baseSchema = Yup.object({
  firstName: Yup.string().required(isDefaultLanguage() ? 'İsim gerekli ' : 'Navn påkreves'),
  lastName: Yup.string().required(isDefaultLanguage() ? 'Soyadı gerekli ' : 'Etternavn påkreves'),
  address: Yup.string().required(isDefaultLanguage() ? 'Adres gerekli ' : 'Adresse påkreves'),
  region: Yup.string().required(isDefaultLanguage() ? 'Şehir gerekli ' : 'By påkreves'),
  country: Yup.string().required(isDefaultLanguage() ? 'Ülke gerekli ' : 'Land påkreves'),
  postalCode: Yup.string()
    .trim()
    .required(isDefaultLanguage() ? 'Posta kodu gerekli ' : 'Postnummer påkreves')
    .matches(/^\d{4,6}$/, {
      message: isDefaultLanguage()
        ? 'Posta kodu 4 basamaklı olmalıdır '
        : 'Postnummeret skal bestå av 4 sifre',
    }),
})

const CartCheckoutForm = () => {
  const { cart } = useCartContext()
  const [isLoading, setIsLoading] = React.useState(false)

  const initialValues = {
    firstName: '',
    lastName: '',
    address: '',
    region: '',
    country: '',
    postalCode: '',
    phone: '',
    email: '',
  }

  const validationSchema = baseSchema.shape({
    phone: Yup.string()
      .trim()
      .required(isDefaultLanguage() ? 'Telefon Numarası gerekli ' : 'Telefonnummer påkreves')
      .matches(/^[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{2,4}$/, {
        message: isDefaultLanguage()
          ? 'Telefon numarası geçerli değil '
          : 'Telefonnummeret er ikke gyldig',
      }),
    email: Yup.string()
      .trim()
      .required(isDefaultLanguage() ? 'E-posta gereklidir ' : 'E-post er påkrevd')
      .email(),
  })

  const { handleSubmit, handleChange, values, errors, isValid, dirty } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      handleCheckout(values)
    },
  })

  const items = cart.map((item) => ({
    price_data: {
      currency: 'nok',
      product_data: {
        name: item.title,
        images: [item.productImage.asset.url],
      },
      unit_amount: formatAmountForStripe(item.price),
    },
    quantity: 1,
  }))

  const handleCheckout = (values) => {
    setIsLoading(true)
    window
      .fetch(`/.netlify/functions/stripe-checkout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          locale: getActiveLang(),
          // TODO: Make sure Billing address can be exported in Stripe!
          customer: values,
          items,
        }),
      })
      .then((res) => {
        return res.json()
      })
      .then(async (checkout) => {
        console.log('checkout', checkout)
        // TODO
        const stripe = await getStripe()
        // When the customer clicks on the button, redirect them to Checkout.
        const result = await stripe.redirectToCheckout({
          sessionId: checkout.id,
        })

        if (result.error) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
          setIsLoading(false)
        }
      })
  }

  return (
    <Form data-testid="shipping-form" onSubmit={handleSubmit}>
      <div>
        <CheckoutInput
          id="firstName"
          name="firstName"
          onChange={handleChange}
          type="text"
          value={values.firstName}
          placeholder={isDefaultLanguage() ? 'İsim *' : 'Fornavn *'}
        />
        <Errors show={errors.firstName}>
          <ErrorSign>
            <MdErrorOutline />
          </ErrorSign>
          <ErrorMsg>{errors.firstName ? errors.firstName : null} </ErrorMsg>
        </Errors>
      </div>
      <div>
        <CheckoutInput
          id="lastName"
          name="lastName"
          onChange={handleChange}
          type="text"
          value={values.lastName}
          placeholder={isDefaultLanguage() ? 'Soyad *' : 'Etternavn *'}
        />
        <Errors show={errors.lastName}>
          <ErrorSign>
            <MdErrorOutline />
          </ErrorSign>
          <ErrorMsg>{errors.lastName ? errors.lastName : null} </ErrorMsg>
        </Errors>
      </div>
      <div>
        <CheckoutInput
          id="address"
          name="address"
          onChange={handleChange}
          type="text"
          value={values.address}
          placeholder={isDefaultLanguage() ? 'Adres *' : 'Adresse *'}
        />
        <Errors show={errors.address}>
          <ErrorSign>
            <MdErrorOutline />
          </ErrorSign>
          <ErrorMsg>{errors.address ? errors.address : null} </ErrorMsg>
        </Errors>
      </div>

      <div>
        <CheckoutInput
          id="phone"
          name="phone"
          onChange={handleChange}
          type="text"
          value={values.phone}
          placeholder={isDefaultLanguage() ? 'Telefon numarası *' : 'Telefonnummer *'}
        />
        <Errors show={errors.phone}>
          <ErrorSign>
            <MdErrorOutline />
          </ErrorSign>
          <ErrorMsg>{errors.phone ? errors.phone : null} </ErrorMsg>
        </Errors>
      </div>
      <div>
        <CheckoutInput
          id="email"
          name="email"
          onChange={handleChange}
          type="text"
          value={values.email}
          placeholder={isDefaultLanguage() ? 'E-posta *' : 'E-post *'}
        />
        <Errors show={errors.email}>
          <ErrorSign>
            <MdErrorOutline />
          </ErrorSign>
          <ErrorMsg>{errors.email ? errors.email : null} </ErrorMsg>
        </Errors>
      </div>

      <div>
        <div>
          <Select
            value={values.country}
            id="country"
            name="country"
            onChange={handleChange}
            css={css`
              margin: 15px 0;
            `}
          >
            <option
              value=""
              label={isDefaultLanguage() ? 'Ülke seçin ... *' : 'Velg land ... *'}
              selected="selected"
            >
              Select a country ...{' '}
            </option>

            <optgroup label="Europe">
              <option value="GB">United Kingdom</option>
              <option value="AL">Albania</option>
              <option value="AD">Andorra</option>
              <option value="AT">Austria</option>
              <option value="BY">Belarus</option>
              <option value="BE">Belgium</option>
              <option value="BA">Bosnia and Herzegovina</option>
              <option value="BG">Bulgaria</option>
              <option value="HR">Croatia (Hrvatska)</option>
              <option value="CY">Cyprus</option>
              <option value="CZ">Czech Republic</option>
              <option value="FR">France</option>
              <option value="GI">Gibraltar</option>
              <option value="DE">Germany</option>
              <option value="GR">Greece</option>
              <option value="VA">Holy See (Vatican City State)</option>
              <option value="HU">Hungary</option>
              <option value="IT">Italy</option>
              <option value="LI">Liechtenstein</option>
              <option value="LU">Luxembourg</option>
              <option value="MK">Macedonia</option>
              <option value="MT">Malta</option>
              <option value="MD">Moldova</option>
              <option value="MC">Monaco</option>
              <option value="ME">Montenegro</option>
              <option value="NL">Netherlands</option>
              <option value="PL">Poland</option>
              <option value="PT">Portugal</option>
              <option value="RO">Romania</option>
              <option value="SM">San Marino</option>
              <option value="RS">Serbia</option>
              <option value="SK">Slovakia</option>
              <option value="SI">Slovenia</option>
              <option value="ES">Spain</option>
              <option value="UA">Ukraine</option>
              <option value="DK">Denmark</option>
              <option value="EE">Estonia</option>
              <option value="FO">Faroe Islands</option>
              <option value="FI">Finland</option>
              <option value="GL">Greenland</option>
              <option value="IS">Iceland</option>
              <option value="IE">Ireland</option>
              <option value="LV">Latvia</option>
              <option value="LT">Lithuania</option>
              <option value="NO">Norway</option>
              <option value="SJ">Svalbard and Jan Mayen Islands</option>
              <option value="SE">Sweden</option>
              <option value="CH">Switzerland</option>
              <option value="TR">Turkey</option>
            </optgroup>
          </Select>
          <Errors show={errors.country}>
            <ErrorSign>
              <MdErrorOutline />
            </ErrorSign>
            <ErrorMsg>{errors.country ? errors.country : null} </ErrorMsg>
          </Errors>
        </div>
      </div>
      <div>
        <CheckoutInput
          id="region"
          name="region"
          onChange={handleChange}
          type="text"
          value={values.region}
          placeholder={isDefaultLanguage() ? 'Şehir *' : 'By *'}
        />
        <Errors show={errors.region}>
          <ErrorSign>
            <MdErrorOutline />
          </ErrorSign>
          <ErrorMsg>{errors.region ? errors.region : null} </ErrorMsg>
        </Errors>
      </div>
      <div>
        <CheckoutInput
          id="postalCode"
          name="postalCode"
          onChange={handleChange}
          type="text"
          value={values.postalCode}
          placeholder={isDefaultLanguage() ? 'Posta kodu *' : 'Postnummer *'}
        />
        <Errors show={errors.postalCode}>
          <ErrorSign>
            <MdErrorOutline />
          </ErrorSign>
          <ErrorMsg>{errors.postalCode ? errors.postalCode : null} </ErrorMsg>
        </Errors>
      </div>

      <CheckoutButton type="submit" disabled={!(isValid && dirty) || isLoading}>
        {isLoading ? (
          <Spinner />
        ) : isDefaultLanguage() ? (
          <>
            Ödeme Sayfasına Git <MdArrowForward />
          </>
        ) : (
          <>
            Forsett Til Betaling <MdArrowForward />
          </>
        )}
      </CheckoutButton>
    </Form>
  )
}

export default CartCheckoutForm
