import React from 'react'
import createLocaleTextGetter from 'src/utils/createLocaleTextGetter'

export const localize = (Component) => {
  return class Localize extends React.Component {
    constructor(props) {
      super(props)
      this.getLocalizedContent = createLocaleTextGetter(this.props.pageContext.locale)
    }
    render() {
      return (
        <Component
          {...this.props}
          data={this.getLocalizedContent(this.props.data)}
          pageContext={this.getLocalizedContent(this.props.pageContext)}
        />
      )
    }
  }
}

export default localize
