import styled from '@emotion/styled'
import { Link } from 'gatsby'
import mediaqueries from '@/styles/media'

const LogoLink = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;

  ${mediaqueries.tablet`
      margin-top: 20px;
      margin-bottom: 20px;
  `}
`

export default LogoLink
