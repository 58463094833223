import React from 'react'
import styled from '@emotion/styled'

import Icons from '@/icons'

const icons = {
  twitter: Icons.Twitter,
  facebook: Icons.Facebook,
  instagram: Icons.Instagram,
}

const SocialLinks = ({ links, fill = '#ffffff' }) => {
  if (!links) return null

  return (
    <h2>
      {Object.keys(links).map((key) => {
        const name = key
        if (name === '_type') return

        const Icon = icons[name]
        if (!Icon) {
          throw new Error(`unsupported social link name=${name} / url=${links[key]}`)
        }
        return (
          <SocialIconContainer
            key={links[key]}
            target="_blank"
            rel="noopener noreferrer"
            data-a11y="false"
            aria-label={`Link to ${links[key]}`}
            href={links[key]}
          >
            <Icon fill={fill} />
            <Hidden>Link to ${links[key]}</Hidden>
          </SocialIconContainer>
        )
      })}
    </h2>
  )
}

export default SocialLinks

const SocialIconContainer = styled.a`
  position: relative;
  text-decoration: none;
  max-width: 16px;

  &:hover {
    svg {
      &:hover * {
        fill: ${(p) => p.theme.colors.primary};
      }
      * {
        transition: fill 0.25s var(--ease-in-out-quad);
      }
    }
  }

  & + a {
    margin-left: 2.2rem;
  }
`

const Hidden = styled.span`
  width: 0px;
  height: 0px;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  display: inline-block;
`
