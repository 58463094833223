import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

const dropdownToggle = (props) =>
  props.dropdownToggle &&
  css`
    &::after {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 0255rem;
      vertical-align: 0.255em;
      content: '';
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
    }
    &:empty::after {
      margin-left: 0;
    }
    &::after {
      margin-left: 0.255em;
    }
  `

const StyledButton = styled.button`
  background: ${(p) => p.theme.colors.secondary};
  color: ${(p) => p.theme.colors.white};
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 29px;
  padding: 15px 29px;
  text-align: center;
  transition: background 0.3s ease-in-out;

  ${({ disabled }) =>
    disabled &&
    `
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.6;
      background: #faa9ad;
  `}

  &:hover {
    background: ${(p) => p.theme.colors.secondary};
  }

  ${(props) =>
    props.dropdownToggle &&
    `
      background: transparent;
      font-family: ${(p) => p.theme.fonts.title};
      text-transform: uppercase;
      font-weight: 500;
      font-size: 1.4rem;
      letter-spacing: -0.01em;
      margin-top: 0;
      padding: 0;
      ${dropdownToggle};

      &:hover {
        background: transparent !important;
      }
    `}
`

const Button = ({ children, component: Component = StyledButton, ...others }) => (
  <Component {...others}>{children}</Component>
)
export default Button
