import React from 'react'
import styled from '@emotion/styled'

const SvgIcon = props => {
  const {
    children,
    htmlColor,
    titleAccess,
    viewBox = '0 0 24 24',
    ...other
  } = props

  return (
    <SVG
      focusable="false"
      viewBox={viewBox}
      htmlColor={htmlColor}
      aria-hidden={titleAccess ? null : 'true'}
      role={titleAccess ? 'img' : 'presentation'}
      {...other}
    >
      {children}
      {titleAccess ? <title>{titleAccess}</title> : null}
    </SVG>
  )
}

export default SvgIcon

const SVG = styled.svg`
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  flex-shrink: 0;
  font-size: 24px;
  transition: fill 0.5s ease;

  color: ${({ color = 'inherit' }) => color};

  ${({ fontSizeSmall }) =>
    fontSizeSmall &&
    `
    font-size: 20px
  `}

  ${({ fontSizeMedium }) =>
    fontSizeMedium &&
    `
    font-size: 26px
  `}

  ${({ fontSizeLarge }) =>
    fontSizeLarge &&
    `
    font-size: 35px
  `}
`
