import React from 'react'
import styled from '@emotion/styled'

const Button = styled.button`
  align-items: center;
  background-color: transparent;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  flex: 0 0 auto;
  font-size: 24px;
  justify-content: center;
  margin: 0;
  outline: 0;
  overflow: visible;
  padding: 12;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  -moz-appearance: none;
  -webkit-appearance: none;
`

const Label = styled.span`
  width: 100%;
  display: flex;
  align-items: inherit;
  justify-content: inherit;
`

const IconButton = ({ children, component: Component = Button, ...others }) => {
  return (
    <Component {...others}>
      <Label>{children}</Label>
    </Component>
  )
}

export default IconButton
