import styled from '@emotion/styled'
import { css } from '@emotion/core'

import Paragraph from '@/components/Paragraph'
import mediaqueries from '@/styles/media'

/**
 * Example:
 * <Heading.h1>Lorem Ipsum</Heading.h1>
 */

const commonStyles = p => css`
  font-weight: bold;
  font-family: ${p.theme.fonts.title};
  color: inherit;
`

const h1 = styled.h1`
  font-size: 52px;
  line-height: 1.35;
  ${commonStyles};

  ${mediaqueries.desktop`
    font-size: 38px;
    line-height: 1.2;
  `};

  ${mediaqueries.phablet`
    font-size: 32px;
    line-height: 1.3;
  `};
`

const h2 = styled.h2`
  font-size: 32px;
  line-height: 1.333;
  ${commonStyles};

  ${mediaqueries.desktop`
    font-size: 21px;
  `};

  ${mediaqueries.tablet`
    font-size: 24px;
    line-height: 1.45;
  `};

  ${mediaqueries.phablet`
    font-size: 22px;
  `};
`

const h3 = styled.h3`
  font-size: 24px;
  line-height: 1.45;
  ${commonStyles};

  ${mediaqueries.tablet`
    font-size: 22px;
  `};

  ${mediaqueries.phablet`
    font-size: 20px;
  `};
`

const h4 = styled.h4`
  font-size: 18px;
  line-height: 1.45;
  ${commonStyles};

  ${mediaqueries.phablet`
    font-size: 16px;
  `};
`

const h5 = styled.h5`
  font-size: 18px;
  line-height: 1.45;
  ${commonStyles};

  ${mediaqueries.phablet`
    font-size: 16px;
  `};
`

const h6 = styled.h6`
  font-size: 16px;
  line-height: 1.45;
  ${commonStyles};

  ${mediaqueries.phablet`
    font-size: 14px;
  `};
`

const HeadTitle = styled(h2)`
  font-size: 35px;
  margin-bottom: 10px;
`

const PageHead = styled(h2)`
  font-size: 25px;
  margin-top: 30px;
  margin-bottom: 20px;

  ${({ marignTop }) =>
    marignTop &&
    `
    margin-top: ${marignTop};
  `}
`

const HeadSubtitle = styled(Paragraph)`
  color: #666666;
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: 1.6;
  margin-bottom: 60px;
  word-spacing: -3px;
  text-align: center;
`

export default {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  HeadTitle,
  HeadSubtitle,
  PageHead,
  commonStyles,
}
