import styled from '@emotion/styled'
import { Link } from 'gatsby'
import mediaqueries from '@/styles/media'

const NavLink = styled(Link)`
  color: ${(p) => p.theme.colors.background};
  display: inline-block;
  font-family: ${(p) => p.theme.fonts.title};
  font-weight: 500;
  font-size: 1.4rem;
  letter-spacing: -0.01em;
  line-height: 1.23;
  padding-left: 3px;
  padding-right: 3px;
  position: relative;
  text-transform: uppercase;
  transition: background 0.2s ease-in-out;

  ${mediaqueries.phone`
    margin-left: 32px;
  `}
`

export default NavLink
