import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import NavLink from '@/components/NavLink'
import Container from '@/components/Container'
import LogoLink from '@/components/LogoLink'
import Dropdown from '@/components/Dropdown'
import IconButton from '@/components/IconButton'
import { getActiveLangPath, isDefaultLanguage } from '@/components/Language'

import { useResizer } from '@/hooks'
import { cartAmountTotal } from '@/selectors/cartQuantity'

import mediaqueries from '@/styles/media'
import Icons from '@/icons'

import { breakpoints } from '@/utils/styles'
import { formatAmountForDisplay } from '@/utils/helpers'

import { useCartContext } from 'context/CartContext'

const CART_HIGHLIGHT_DELAY = 3000

const Navigation = ({ transparent, scrolled, navMenuItems, locale }) => {
  const { cart, isOpenCart, setIsOpenCart } = useCartContext()
  const toggleCart = React.useCallback(() => setIsOpenCart(!isOpenCart), [
    isOpenCart,
    setIsOpenCart,
  ])

  const isMobile = useResizer()
  const [isNavMenuOpen, setIsNavMenuOpen] = React.useState(false)

  const [, forceUpdate] = React.useState()
  const mountedRef = React.useRef(false)
  const timeoutRef = React.useRef(null)

  const [expanded, setExpanded] = React.useState(false)

  React.useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      if (expanded) {
        setExpanded(false)
      }
    }, CART_HIGHLIGHT_DELAY)

    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [expanded])

  React.useEffect(() => {
    if (mountedRef.current && cart.length > 0) {
      setExpanded(true)
    }
  }, [cart])

  // NOTE: mountEffect *must* run as last effect!
  React.useEffect(() => {
    mountedRef.current = true
    if (mountedRef.current) {
      forceUpdate({}) // forceupdate to rerender after ref changes
    }
    return () => {
      mountedRef.current = false
    }
  }, [])

  return (
    <MenuBar transparent={transparent} scrolled={scrolled}>
      <Container>
        <NavContainer>
          <LogoLink
            to={`${getActiveLangPath()}/`}
            data-a11y="false"
            title="Navigate back to the homepage"
            aria-label="Navigate back to the homepage"
          >
            <SVLogo />
          </LogoLink>
          <Nav isNavMenuOpen={isNavMenuOpen}>
            <DonationLinkSticky to={isDefaultLanguage() ? '/urunler' : '/no/produkter'}>
              {isDefaultLanguage() ? 'Bağış ' : 'Donasjon '}
              <Cart aria-label={`Shopping cart with ${cart.length} items`} expanded={expanded}>
                {mountedRef.current && (
                  <LabelContainer>
                    {cart.length > 0 && (
                      <span>{formatAmountForDisplay(cartAmountTotal(cart))}</span>
                    )}
                  </LabelContainer>
                )}
              </Cart>
            </DonationLinkSticky>

            <Cart aria-label={`Shopping cart with ${cart.length} items`} onClick={toggleCart}>
              <IconContainer>
                <ItemsNumber>{cart?.length}</ItemsNumber>
                <Icons.Cart />
              </IconContainer>
            </Cart>

            <BurgetButton
              onClick={() => setIsNavMenuOpen((prev) => !prev)}
              color="inherit"
              aria-haspopup="true"
              aria-expanded={isNavMenuOpen}
              aria-label="Toggle main menu"
            >
              {isNavMenuOpen ? (
                <Icons.Cross fontSizeMedium color={isNavMenuOpen ? 'black' : 'white'} />
              ) : (
                <Icons.Burger fontSizeMedium color={isNavMenuOpen ? 'black' : 'white'} />
              )}
            </BurgetButton>
          </Nav>
          <Nav isNavMenuOpen={isNavMenuOpen}>
            {navMenuItems.map((navMenu) => (
              <Dropdown
                key={navMenu.title}
                title={navMenu.title}
                to={navMenu.slug.current}
                items={navMenu.items}
                onClick={() => setIsNavMenuOpen(false)}
              />
            ))}
            {!isMobile && (
              <>
                <DonationButtonWrapper to={isDefaultLanguage() ? '/urunler' : '/no/produkter'}>
                  <span>{isDefaultLanguage() ? 'Bağış' : 'Donasjon'}</span>

                  <Cart aria-label={`Shopping cart with ${cart.length} items`} expanded={expanded}>
                    {mountedRef.current && (
                      <LabelContainer>
                        {cart.length > 0 && (
                          <span>{formatAmountForDisplay(cartAmountTotal(cart))}</span>
                        )}
                      </LabelContainer>
                    )}
                  </Cart>
                </DonationButtonWrapper>
                <Cart aria-label={`Shopping cart with ${cart.length} items`} onClick={toggleCart}>
                  <IconContainer>
                    <ItemsNumber>{cart?.length}</ItemsNumber>
                    <Icons.Cart />
                  </IconContainer>
                </Cart>
              </>
            )}
          </Nav>
        </NavContainer>
      </Container>
    </MenuBar>
  )
}

Navigation.propTypes = {
  siteTitle: PropTypes.string,
}

Navigation.defaultProps = {
  siteTitle: ``,
}

export default Navigation

const MenuBar = styled.header`
  position: fixed;
  top: 44px;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 3;
  background: ${(p) =>
    p.transparent
      ? 'linear-gradient(rgba(0, 0, 0, 0.3) 65%, rgba(0, 0, 0, 0))'
      : p.theme.colors.stickyNavBackground};
  transition: background 300ms ease-out;

  box-shadow: ${(p) =>
    p.transparent
      ? 'none'
      : 'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px'};
`
const NavContainer = styled.nav`
  // position: relative;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
  padding-top: 2rem;
  padding-bottom: 2rem;

  @media (max-width: ${breakpoints.m}px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
    flex-direction: column;
  }

  @media (max-width: ${breakpoints.s}px) {
    flex-direction: row;
  }
`

const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;

  &:first-of-type {
    display: none;
  }

  @media (max-width: ${breakpoints.m}px) {
    &:first-of-type {
      flex: 1 1 0%;
      display: flex;
      justify-content: flex-end;
    }

    &:last-of-type {
      display: none;
    }

    ${({ isNavMenuOpen }) =>
      isNavMenuOpen &&
      `

      &:last-of-type {
        display: flex;
        position: fixed;
        height: 100vh;
        width: 100vw;
        background-color: white;
        left: 0;
        right: 0;
        top: 0;
        border: 0;
        flex-direction: column;
        z-index: 1;
        & > button {
          color: black;
        }

        & > div {
          margin: 0;
          margin-bottom: 3rem;

          & > button, & > a {
            margin: 0;
            font-size: 2rem;
            color: black;
          }
        }
      }
    `}
  }

  @media (min-width: ${breakpoints.m}px) {
    &:first-of-type {
      display: none;
    }
    &:last-of-type {
      display: flex;
    }
  }
`

const BurgetButton = styled(IconButton)`
  display: none;

  ${mediaqueries.tablet`
    display: block;
    margin-left: 10px;
    z-index: 2;
  `}
`

const SVLogo = styled(Icons.LogoSwedish)`
  font-size: 3em;
  width: 4em;
  @media (max-width: ${breakpoints.m}px) {
    width: 3em;
  }
`

const Cart = styled(IconButton)`
  margin-left: 0.5rem;
  color: white;
  font-size: 2.2rem;

  ${({ expanded }) =>
    expanded &&
    `
  ${LabelContainer} {
   max-width: 100px;
   margin-left: 10px;
  }

  `}
  @media (max-width: ${breakpoints.s}px) {
    font-size: 2.4rem;
    margin: 0;
  }
`

const LabelContainer = styled.div`
  overflow: hidden;
  max-width: 0;
  font-size: 1.5rem;
  transition: max-width 0.5s;
`

const IconContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;
`
const ItemsNumber = styled.span`
  position: absolute;
  top: -3px;
  left: 10px;
  background: #ff3a46;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 600;
  width: 14px;
  border-radius: 50px;
`

const DonationButtonWrapper = styled(NavLink)`
  background-color: ${(p) => p.theme.colors.secondary};
  color: ${(p) => p.theme.colors.white};
  border: 2px dotted #faa9ad;
  display: inline-block;
  font-size: 1.3rem;
  font-weight: 400;
  text-transform: uppercase;
  margin-left: 2rem;
  padding: 7px 10px;
  text-align: center;
  transition: background 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: #ee3742;
    border: 2px dotted #faa9ad;
  }

  @media (max-width: ${breakpoints.s}px) {
    margin-left: 0;
    margin-bottom: 3rem;
  }
`

const DonationLinkSticky = styled(DonationButtonWrapper)`
  background-color: ${(p) => p.theme.colors.secondary};
  color: ${(p) => p.theme.colors.white};
  border: 2px dotted #faa9ad;
  display: inline-block;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  transition: background 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: #ee3742;
    border: 2px dotted #faa9ad;
  }

  @media (max-width: ${breakpoints.s}px) {
    margin-left: 0;
    margin-bottom: 3rem;
  }

  margin-bottom: 0 !important;
  font-size: 1.2rem;
  height: 52px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`
