import { useState, useEffect, useCallback, useRef } from 'react'
let defaultWidth

if (typeof window !== `undefined`) {
  defaultWidth = window.innerWidth
}

export function WithIsScrolled(props) {
  const [isScrolled, setIsScrolled] = useState(false)

  const onScroll = useCallback(() => {
    setIsScrolled((window.pageYOffset || document.body.scrollTop) > 52)
  }, [])

  useEffect(() => {
    // invariant(typeof props.children === 'function', 'The children prop is expected to be a function');

    // Learn more about how { passive: true } improves scrolling performance
    // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Improving_scrolling_performance_with_passive_listeners
    window.addEventListener('scroll', onScroll, { passive: true })
    return () => window.removeEventListener('scroll', onScroll, { passive: true })
  }, [onScroll])

  return props.children({ isScrolled })
}

export function usePrevious(value) {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

export function useResizer(value = 640) {
  const [isMobile, setIsMobile] = useState(defaultWidth < value)

  function handleSizeChange() {
    return setIsMobile(defaultWidth < value)
  }

  useEffect(() => {
    window.addEventListener('resize', handleSizeChange)

    return () => {
      window.removeEventListener('resize', handleSizeChange)
    }
  }, [isMobile])

  return isMobile
}

// Our hook
export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(
    () => {
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      // Return a cleanup function that will be called every time ...
      // ... useEffect is re-called. useEffect will only be re-called ...
      // ... if value changes (see the inputs array below).
      // This is how we prevent debouncedValue from changing if value is ...
      // ... changed within the delay period. Timeout gets cleared and restarted.
      // To put it in context, if the user is typing within our app's ...
      // ... search box, we don't want the debouncedValue to update until ...
      // ... they've stopped typing for more than 500ms.
      return () => {
        clearTimeout(handler)
      }
    },
    // Only re-call effect if value changes
    // You could also add the "delay" var to inputs array if you ...
    // ... need to be able to change that dynamically.
    [value]
  )

  return debouncedValue
}
