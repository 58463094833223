import React from 'react'
import SVG from '@/components/SvgIcon'

const Cross = props => (
  <SVG {...props}>
    <polygon points="19.159 5.371 18.629 4.841 12 11.47 5.371 4.841 4.841 5.371 11.47 12 4.841 18.629 5.371 19.159 12 12.53 18.629 19.159 19.159 18.629 12.53 12 19.159 5.371" />
  </SVG>
)

export default Cross
