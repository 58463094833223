// Social
import Facebook from './social/Facebook.Icon'
import Instagram from './social/Instagram.Icon'
import Twitter from './social/Twitter.Icon'
import Swedish from './Swedish.Icon'
import Turkish from './Turkish.Icon'
import Burger from './Burger'
import Cart from './Cart'
import Cross from './Cross'
import LogoSwedish from './LogoSwedish'
import LogoTurkish from './LogoTurkish'
import Sad from './Sad'

export default {
  Facebook,
  Instagram,
  Twitter,
  Swedish,
  Turkish,
  Burger,
  Cart,
  Cross,
  LogoSwedish,
  LogoTurkish,
  Sad,
}
