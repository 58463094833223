import React from 'react'
import styled from '@emotion/styled'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Location } from '@reach/router'
import { Link as GatsbyLink } from 'gatsby'

import IconButton from '@/components/IconButton'
import Icons from '@/icons'

import { breakpoints } from '@/utils/styles'

let selectedLang = null

if (typeof window !== 'undefined' && window.location) {
  if (window.location.pathname.includes('/no')) {
    selectedLang = 'no'
  }
}

function setActiveLang(lang) {
  selectedLang = lang
}

export function getActiveLang() {
  if (!selectedLang || selectedLang === 'tr') {
    return 'tr'
  }
  return selectedLang
}

export function isDefaultLanguage() {
  return !selectedLang || selectedLang === 'tr'
}

export function getActiveLangPath() {
  if (!selectedLang || selectedLang === 'tr') {
    return ''
  }
  return `/${selectedLang}`
}

export function getUrl(location, country) {
  const langPath = country !== 'tr' ? `/${country}` : ''

  const url = location.pathname.replace(`/${selectedLang}`, '')

  return langPath + url
}

const Language = ({ location }) => {
  return (
    <Wrapper>
      <IconButton
        component={GatsbyLink}
        onClick={() => {
          setActiveLang('tr')
        }}
        to="/"
        style={{
          marginLeft: 10,
        }}
      >
        <Icons.Turkish color="white" fontSizeSmall />
      </IconButton>
      <IconButton
        component={GatsbyLink}
        onClick={() => {
          setActiveLang('no')
        }}
        to="/no/"
        style={{
          marginLeft: 10,
        }}
      >
        <Icons.Swedish color="white" fontSizeSmall />
      </IconButton>
    </Wrapper>
  )
}

export default function SelectLanguageWrapper() {
  const cb = React.useCallback(({ location }) => {
    return <Language location={location} />
  }, [])
  return <Location>{cb}</Location>
}

const Wrapper = styled.div`
  border-left: 1px solid #adefee;
  margin-left: 12px;
  display: flex;

  @media (max-width: ${breakpoints.s}px) {
    border: none;
  }
`
