import styled from '@emotion/styled'
import { breakpoints } from '@/utils/styles'

export const Input = styled(`input`)`
  border: none;
  background: transparent;
  border: 1px solid #ebebeb;
  color: #434343;
  border-radius: 0;
  padding: 0 25px;
  display: block;
  width: 100%;
  font-size: 15px;
  line-height: 1.45;
  outline: none;
  box-shadow: none;

  :focus {
    box-shadow: 0 0 0 3px ${(p) => p.theme.colors.accent};
    outline: 0;
    transition: box-shadow 0.15s ease-in-out;
  }

  ${({ disabled }) =>
    disabled &&
    `
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.6;
  `}
`

export const CheckoutInput = styled(Input)`
  font-size: 13px;
  height: 45px;
  padding: 0 10px;
  margin: 15px 0;
`

export const Select = styled.select`
  border: none;
  background: transparent;
  border: 1px solid #ebebeb;
  color: #434343;
  border-radius: 0;
  padding: 0 25px;
  display: block;
  width: 100%;
  font-size: 13px;
  line-height: 1.45;
  outline: none;
  box-shadow: none;
  height: 45px;
  padding: 0 10px;

  :focus {
    box-shadow: 0 0 0 3px ${(p) => p.theme.colors.accent};
    outline: 0;
    transition: box-shadow 0.15s ease-in-out;
  }

  ${({ disabled }) =>
    disabled &&
    `
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.6;
  `}
`

export const Fieldset = styled(`fieldset`)`
  border: none;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0;
  padding: 0;
`

export const Label = styled(`label`)`
  color: ${(p) => p.theme.colors.primary};
  display: flex;
  font-size: 1rem;
  padding: 8px;
`

export const Form = styled.form`
  background: ${(p) => p.theme.colors.white};
  padding: 15px;

  /* display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${breakpoints.s}px) {
    margin-top: 20px;
    width: 100%;
    justify-content: space-between;
  } */
`

export const Errors = styled(`div`)`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: row;
  width: 100%;
  // padding-bottom: 12px;
`

export const ErrorSign = styled(`div`)`
  align-items: center;
  background: ${(p) => p.theme.colors.errros};
  border-radius: 2px 0 0 2px;
  /* color: ${(p) => p.theme.colors.card}; */
  display: flex;
  flex-basis: 40px;
  justify-content: center;

  svg {
    height: 20px;
    width: 20px;
    fill: ${(p) => p.theme.colors.error};
  }
`

export const ErrorMsg = styled.span`
  border-left: none;
  border-radius: 0 2px 2px 0;
  color: ${(p) => p.theme.colors.error};
  flex-grow: 1;
`
