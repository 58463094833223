import React from 'react'
import { isDefaultLanguage } from '@/components/Language'

import CartListItem from './CartListItem'
import { CartListRoot, Headers } from './styles'

const CartList = ({ products }) => {
  return (
    <CartListRoot>
      <Headers>
        <span>{isDefaultLanguage() ? 'BAĞIŞ TÜRÜ' : 'DONASJONER'}</span>
        <span>{isDefaultLanguage() ? 'ADET' : 'ANTALL'}</span>
        <span>{isDefaultLanguage() ? 'SIL' : 'TA BORT'}</span>
      </Headers>

      {products.map((product) => (
        <CartListItem key={product.id} product={product} />
      ))}
    </CartListRoot>
  )
}

export default CartList
