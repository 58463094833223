import React from 'react'
import SVG from '@/components/SvgIcon'

const Swedish = (props) => (
  <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
    <path
      fill="#ff4b55"
      d="M503.172 423.725H8.828A8.829 8.829 0 010 414.897V97.104a8.829 8.829 0 018.828-8.828h494.345a8.829 8.829 0 018.828 8.828v317.793a8.83 8.83 0 01-8.829 8.828z"
    />
    <path fill="#f5f5f5" d="M512 211.863H229.517V88.277h-88.276v123.586H0v88.276h141.241v123.586h88.276V300.139H512z" />
    <path fill="#41479b" d="M512 229.518H211.862V88.277h-52.965v141.241H0v52.966h158.897v141.241h52.965V282.484H512z" />
  </SVG>
)

export default Swedish
