import styled from '@emotion/styled'

const DropdownDivider = styled.div`
  height: 0;
  padding-top: 0.5rem;
  margin-top: 0.5rem;
  border-top: 1px solid #000;
`

export { DropdownDivider }
