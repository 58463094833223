import React from 'react'
import { MdClose } from 'react-icons/md'

import IconButton from '@/components/IconButton'
import { isDefaultLanguage } from '@/components/Language'

import { cartAmountTotal } from '@/selectors/cartQuantity'
import { formatAmountForDisplay } from '@/utils/helpers'

import CartList from './CartList'
import EmptyCart from './EmptyCart'
import CartCheckoutForm from './CartCheckoutForm'

import { useCartContext } from 'context/CartContext'

import {
  CartRoot,
  Heading,
  Title,
  Content,
  ItemsNumber,
  ItemsInCart,
  Costs,
  Total,
  CartTitleWrapper,
  CheckoutWrapper,
} from './styles'

const Cart = () => {
  const { cart, isOpenCart, setIsOpenCart } = useCartContext()
  const toggleCart = () => setIsOpenCart(!isOpenCart)

  return (
    <CartRoot isOpenCart={isOpenCart} isEmptyCart={!cart.length > 0}>
      {isOpenCart ? (
        <>
          <Heading isEmptyCart={!cart.length > 0}>
            <IconButton
              onClick={toggleCart}
              aria-label={`Shopping cart with items`}
              style={{
                fontSize: '2.2rem',
              }}
            >
              <MdClose />
            </IconButton>
            {/* <CartIndicator itemsInCart={itemsInCart} adding={adding} /> */}
            {cart?.length > 0 ? (
              <CartTitleWrapper>
                <Title>{isDefaultLanguage() ? 'Bağış Sepetiniz' : 'Donasjonskurv'}</Title>
                <ItemsInCart>
                  {isDefaultLanguage() ? 'TOPLAM ADET' : 'TOTALT ANTALL'}
                  <ItemsNumber>{cart?.length}</ItemsNumber>
                </ItemsInCart>
              </CartTitleWrapper>
            ) : (
              <EmptyCart isEmptyCart={true} />
            )}
          </Heading>
          {cart?.length > 0 && (
            <Content>
              <CartList products={cart} />
              <Costs>
                <Total>
                  <span>{isDefaultLanguage() ? 'TOPLAM BAĞIŞ' : 'SUM'}</span>
                  <strong>{formatAmountForDisplay(cartAmountTotal(cart))}</strong>
                </Total>
              </Costs>
              <CheckoutWrapper>
                <CartCheckoutForm cart={cart} />
              </CheckoutWrapper>
            </Content>
          )}
        </>
      ) : null}
    </CartRoot>
  )
}

export default React.memo(Cart)
