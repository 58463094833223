import styled from '@emotion/styled'
import { css } from '@emotion/core'

import mediaqueries from '@/styles/media'
import { breakpoints } from '@/utils/styles'

const Container = styled.div`
  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
  padding-top: 0;
  padding-right: 4rem;
  padding-bottom: 0;
  padding-left: 4rem;
  z-index: 1;

  ${({ paddingVertical }) =>
    paddingVertical &&
    css`
      padding-top: ${paddingVertical}px;
      padding-bottom: ${paddingVertical}px;
    `}

  ${({ paddingHorizontal }) =>
    paddingHorizontal &&
    css`
      padding-top: ${paddingHorizontal}px;
      padding-bottom: ${paddingHorizontal}px;
    `}

  ${({ paddingTop }) =>
    paddingTop &&
    css`
      @media (min-width: ${breakpoints.m}px) {
        padding-top: ${paddingTop}px;
      }
    `}

  ${({ paddingBottom }) =>
    paddingBottom &&
    css`
      padding-bottom: ${paddingBottom}px;

      @media (max-width: ${breakpoints.l}px) and (min-width: ${breakpoints.s}px) {
        padding-right: ${paddingBottom}px !important;
        padding-left: ${paddingBottom}px !important;
      }
    `}
  ${(p) =>
    p.narrow
      ? mediaqueries.tablet`
      padding: 0 2rem;
      max-width: 527px;
    `
      : mediaqueries.tablet`
      max-width: 567px;
  `}

    @media (min-width: ${breakpoints.l}px) {
    max-width: 1024px;
  }

  @media (max-width: ${breakpoints.m}px) {
    padding-right: 2rem;
    padding-left: 2rem;
  }
`

export default Container
